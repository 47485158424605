import React from "react"
import { Link } from "gatsby"
import Image from "@/components/general/Image"
// import Layout from "@/components/layout"
// import Image from "@/components/image"
import SEO from "@/components/seo"
import Opener from "@/components/general/Opener"
import Sizing from "@/components/general/Sizing"
import Parallax from "@/components/general/Parallax"
import Heading from "@/components/general/Heading"
import RentsGraph from "@/components/graphs/RentsGraph"
import ColorChangeBG from "@/components/general/ColorChangeBG"
import Spacing from "@/components/general/Spacing"
import Signup from "@/components/general/Signup"
import Dropcap from "@/components/general/Dropcap"
import Share from "@/components/general/Share"
import FooterNav from "@/components/general/FooterNav"
import Motif from "@/components/general/Motif"
import Footer from "@/components/general/Footer"
import pullquote from "@/images/rents/pullquote.svg"
import pullquote2 from "@/images/rents/pullquote2.svg"

import openerText from "@/images/rents/opener-text.png"
import openerTextSml from "@/images/rents/opener-text-sml.png"

import dci from "@/images/rents/dci.png"
import shareImg from "@/images/og/og_rents.jpg"
import SignupPopup from "@/components/general/SignupPopup"

const RentsPage = ({pageContext}) => {
    return (
        <>
            <SEO
                page="/high-rents"
                title="Free Markets Destroy | Learn How Free Markets Destroy High Rents"
                description="Across America and Europe, home prices have been soaring for three decades. Meanwhile, incomes have flatlined. But rental prices aren't rising everywhere."
                img={shareImg}
            />

            <Opener
                pageName="rents"
                titles={[
                    { img: openerText, size: 14 },
                    { img: openerTextSml, size: 22 },
                ]}
            ></Opener>

            {
                // ======================================= SECTION 1 =======================================
            }

            <Sizing
                styles={{ float: "left", position: "relative", zIndex: 4 }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 20,
                            marginBottom: 1,
                            // marginTop: 2,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginLeft: 2,
                            width: 10,
                            marginBottom: 1,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <div className="copy--lead" style={{ position: "relative" }}>
                    <Dropcap img={dci}>I</Dropcap>n 2018, the National Low
                    Income Housing Coalition announced that a renter earning
                    minimum wage at a full-time job could afford a typical
                    two-bedroom apartment in exactly zero U.S. counties. Zero.
                    The same full-time minimum-wage worker could afford a
                    one-bedroom apartment in just 22 out of more than 3,000
                    counties nationwide. To call this an affordability crisis
                    seems like a massive understatement.
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -9,
                                    bottom: -1,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="rents" id="motif_1" movement={-0.1} />
                    </Sizing>
                </div>

                <p className="copy">
                    Across America and Europe, home prices have been soaring for
                    three decades. Meanwhile, incomes have flatlined. In cities
                    like London, Boston, and Seattle, the ratio of home prices
                    to earnings has doubled or even tripled in the last 10
                    years. And restrictions aimed at limiting growth and pumping
                    public money into subsidized housing have actually made the
                    problem worse—much worse—in many of America’s most
                    unaffordable cities.
                </p>
                <div style={{ position: "relative" }}>
                    <p className="copy">
                        But rental prices aren’t rising everywhere. In Tokyo,
                        home prices have remained relatively stable for 25
                        years. What gives? With an estimated population of 38
                        million, the greater Tokyo area is the most populous
                        metropolitan region in the world, larger than New York
                        City and Los Angeles combined. Tokyo is a global
                        financial center, home to 36 of the world’s largest
                        companies, and a major hub for media, design and
                        manufacturing. So why hasn’t the affordability crisis
                        hit the Japanese capital?{" "}
                    </p>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -6,
                                    bottom: 0,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="rents" id="motif_2" movement={0.1} />
                    </Sizing>
                </div>
            </Sizing>

            <div style={{ float: "left", clear: "left" }}>
                <SignupPopup></SignupPopup>
            </div>

            <RentsGraph></RentsGraph>

            <ColorChangeBG
                from={{ r: 54, g: 153, b: 221 }}
                to={{ r: 104, g: 197, b: 184 }}
            >
                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                                // marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 4,
                                width: 6,
                                marginBottom: 1,
                                marginTop: 2,
                            },
                        },
                    ]}
                >
                    <img
                        style={{
                            width: "100%",
                        }}
                        src={pullquote}
                        alt=""
                    />

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -2,
                                    bottom: -4,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="rents" id="motif_9" movement={-0.2} />
                    </Sizing>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                                marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 8,
                                marginBottom: 1,
                                marginTop: 2,
                            },
                        },
                    ]}
                >
                    <p className="copy">
                        One major reason, according to experts, is markets. With
                        essentially no rent controls and very few restrictions
                        on height and density, Tokyo is the rare modern city
                        where supply can respond to demand in real time. In
                        other words, it’s a free market. Prices rise and fall as
                        the supply of new and available housing changes to meet
                        the needs of residents.
                    </p>
                    <p className="copy">
                        Adjusted for population, Tokyo approves two “housing
                        starts”—new residential construction projects—for every
                        one in New York City. That’s why the average rent for a
                        two-bedroom apartment in Tokyo was less than $1,000 per
                        month as recently as 2018—less than half the cost of the
                        same apartment in Seattle.
                    </p>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 1,
                                width: 22,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 1,
                                width: 18,
                                paddingBottom: 0.3,
                            },
                        },
                    ]}
                >
                    <Image style={{ width: "100%" }} src="rents_capsule" />
                </Sizing>

                <Sizing
                    styles={{ float: "right", textAlign: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 1,
                                width: 22,
                                marginTop: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 1,
                                width: 18,
                                marginTop: -0.8,
                            },
                        },
                    ]}
                >
                    <span className="caption">
                        - Tokyo's Nakagin Capsule Tower, designed by architect
                        Kisho Kurokawa.
                    </span>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 4,
                                width: 18,
                                marginBottom: 1,
                                marginTop: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 2,
                                marginRight: 2,
                                width: 4,
                                minWidth: "350px",
                            },
                        },
                    ]}
                >
                    <Signup color="#EC5843"></Signup>
                </Sizing>

                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                // marginBottom: 1,
                                marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: { marginTop: 2, marginLeft: 3, width: 8 },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -3,
                                    top: -4,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="rents" id="motif_3" movement={0.1} />
                    </Sizing>

                    <p className="copy--lead">
                        And in San Francisco, where strict housing regulations
                        and misguided attempts at rent control have produced the
                        highest housing costs of any major American city—nearly
                        $4,000 for a typical one-bedroom apartment.
                    </p>

                    <p className="copy">
                        In 2015, after San Francisco's Board of Supervisors
                        attempted to pass a “moratorium” blocking all new
                        housing development in the popular Mission District, San
                        Francisco City Supervisor Scott Wiener (now a California
                        state senator) published an op-ed titled “Yes, Supply
                        and Demand Apply to Housing, Even in San Francisco.”
                    </p>

                    <p className="copy">
                        Wiener declared, “We’ve never come close to producing
                        enough housing to allow anyone to argue that increasing
                        housing supply doesn’t stabilize housing prices. But, we
                        do have evidence to the contrary. Since 2003, San
                        Francisco has grown by nearly 100,000 people, while
                        producing around 24,000 units of housing. During that
                        same time period, housing prices have gone through the
                        roof.”
                    </p>

                    <p className="copy">
                        Wiener concluded, “Economic principles aren’t always
                        convenient, but they are real. Supply and demand exists,
                        and it applies in San Francisco.”
                    </p>
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative", zIndex: 2 }}
                    breaks={[
                        {
                            min: 0,
                            val: { display: "none" },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 4,
                                marginRight: 3,
                                width: 7,
                                display: "block",
                            },
                        },
                    ]}
                >
                    <img
                        style={{
                            width: "100%",
                        }}
                        src={pullquote2}
                        alt=""
                    />
                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: { paddingTop: 0.3 },
                            },
                        ]}
                    >
                        <span
                            className={"caption caption--large caption--white"}
                        >
                            Scott Wiener – City Supervisor
                        </span>
                    </Sizing>
                </Sizing>
            </ColorChangeBG>
            <ColorChangeBG
                from={{ r: 104, g: 197, b: 184 }}
                to={{ r: 183, g: 131, b: 185 }}
            >
                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: 3,
                                paddingBottom: 0.3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: -1,
                                paddingBottom: 0.3,
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: -3,
                                paddingBottom: 0.3,
                            },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -4,
                                    top: 3,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="rents" id="motif_2" movement={-0.15} />
                    </Sizing>
                    <Image
                        style={{ width: "100%" }}
                        src={"rents_tents"}
                    ></Image>
                </Sizing>
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 3,
                                width: 18,
                                marginBottom: 3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 5,
                                width: 15,
                                marginBottom: 0,
                            },
                        },
                    ]}
                >
                    <span className="caption">
                        - Homeless encampment in Seattle.
                    </span>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                // marginBottom: 1,
                                marginTop: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: { marginLeft: 4, width: 8, marginTop: 1 },
                        },
                    ]}
                >
                    <Heading>THE MIRACLE OF H-TOWN</Heading>
                </Sizing>
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                // marginBottom: 1,
                                marginTop: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: { marginLeft: 5, width: 10, marginTop: 2 },
                        },
                        {
                            min: 1200,
                            val: { marginLeft: 7, width: 8, marginTop: 2 },
                        },
                    ]}
                >
                    <p className="copy--lead">
                        One U.S. city that has succeeded at keeping rents
                        relatively low, even as the local population has
                        exploded, is Houston, Texas.
                    </p>

                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        left: -4.5,
                                        top: 0,
                                        width: 1.2,
                                    },
                                },
                            ]}
                        >
                            <Motif page="rents" id="motif_6" movement={-0.15} />
                        </Sizing>
                        <p className="copy">
                            In the first decade of the 21st century, the greater
                            Houston area grew by a staggering 20%, ballooning
                            from 4.7 to 6 million residents, but during that
                            same period rents actually decreased. So what’s the
                            secret to Houston’s sustained affordability?
                            Deregulation.
                        </p>
                    </div>
                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        right: -7,
                                        top: 0,
                                        width: 1.2,
                                    },
                                },
                            ]}
                        >
                            <Motif page="rents" id="motif_5" movement={-0.15} />
                        </Sizing>

                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        right: -7,
                                        top: 4,
                                        width: 2,
                                    },
                                },
                            ]}
                        >
                            <Motif page="rents" id="motif_8" movement={-0.2} />
                        </Sizing>
                        <p className="copy">
                            Houston is the only large city in America with no
                            official zoning restrictions. The results can be
                            eccentric: Adult video stores located next to
                            corporate offices, modern churches wedged between
                            warehouses, and residential buildings in a wide
                            variety of shapes, colors, and styles. But at the
                            end of the day, this laissez faire approach to
                            housing has helped Houston remain one of the few
                            American cities where Millennials can afford to buy
                            first homes.
                        </p>
                    </div>

                    <p className="copy">
                        “Houston’s success,” wrote Harvard economist Edward
                        Glaeser, “shows that a relatively deregulated
                        free-market city, with a powerful urban growth machine,
                        can do a much better job of taking care of middle-income
                        Americans.”
                    </p>

                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        left: -6,
                                        top: 0,
                                        width: 2,
                                    },
                                },
                            ]}
                        >
                            <Motif page="rents" id="motif_8" movement={-0.2} />
                        </Sizing>

                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        right: -2.5,
                                        top: -1,
                                        width: 1,
                                    },
                                },
                            ]}
                        >
                            <Motif page="rents" id="motif_4" movement={0.15} />
                        </Sizing>

                        <p className="copy">
                            In large cities, restrictions and regulations
                            advertised as “protections” tend to insulate wealthy
                            neighborhoods from low-income renters. Historically,
                            these restrictions were overtly racist, the
                            machinations of city councils and “experts” who
                            wanted to exclude people of color, and other
                            marginalized groups. Even when regulations are
                            well-intentioned they often have the intended effect
                            of making cheap housing illegal by banning tall
                            buildings, small apartments, and anything eccentric
                            or ugly.
                        </p>
                    </div>

                    <p className="copy">
                        The road to an affordability crisis is paved with
                        regulations. If we want to bring rents down and create
                        more opportunities for everyone, we need to follow
                        Tokyo’s lead and unleash the power of markets.
                    </p>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 0,
                                width: 24,
                                marginBottom: 4,
                                marginTop: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: -2,
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 2,
                            },
                        },
                    ]}
                >
                    <Image src="rents_demo" style={{ width: "100%" }}></Image>
                </Sizing>

                <Spacing space="0" />
                <Sizing
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: 20,
                                maxWidth: "300px",
                                height: "50px",
                                marginTop: 2,
                                paddingBottom: 5,
                                position: "relative",
                                display: "block",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                display: "none",
                            },
                        },
                    ]}
                >
                    <Share></Share>
                </Sizing>
            </ColorChangeBG>
            <FooterNav next={"climate"} previous={"hunger"} langKey={pageContext.langKey}/>
            <Footer></Footer>
        </>
    )
}

export default RentsPage
